@import "../../../app.scss";

.skillContainer {
  display: flex;
  text-align: start;
  gap: 4px;

  svg{
    margin-top: 4px;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 5px;

    h4 {
      font-size: 20px;
      font-weight: 300;

      @include mobile{
        font-size: 16px;
      }
    }

    span {
      color: gray;
      font-size: 16px;

      @include mobile{
        font-size: 12px;
      }
    }
  }
}
