@import "../../app.scss";

.background {
  background: linear-gradient(180deg, #0c0c1d, #111132);
  height: 100%;

  .skills {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .textContainer {
      flex: 1;
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 20px;

      @include mobile {
        flex: 0.1;
        flex-direction: column;
        gap: 5px;
      }

      p {
        font-weight: 200;
        font-size: 20px;
        color: gray;
        text-align: right;

        @include mobile {
          font-size: 16px;
        }
      }

      hr {
        width: 500px;
        border: none;
        border-top: 0.5px solid grey;

        @include mobile {
          width: 220px;
        }
      }
    }

    .wrapper {
      margin: auto;
      flex: 1;
      width: 100%;
      max-width: 1366px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      gap: 50px;

      @include mobile {
        gap: 10px;
        height: 90%;
      }

      h1 {
        font-size: 72px;
        color: orange;

        @include mobile {
          font-size: 46px;
        }
      }

      .container {
        width: 100%;
        display: flex;
        gap: 50px;
        justify-content: center;
        margin-bottom: 20px;;

        @include mobile {
          flex-direction: column;
          gap: 20px;
          height: 100%;
          align-items: center;
          margin-bottom: 0;
        }

        .content {
          max-width: 600px;
          flex: 1;
          background-color: #111132;
          padding: 20px;
          border: 1px solid rgba(255, 255, 255, 0.178);
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 30px;
          transition: background-color 0.3s ease;
          color: white;
          width: 100%;
          // box-shadow: 2px 2px 4px rgba(153, 150, 150, 0.842), -2px -2px 4px rgba(241, 232, 232, 0.767);;

          @include mobile {
            gap: 15px;
            padding: 10px;
            max-height: 45%;
          }

          &:hover {
            background-color: lightgray;
            color: black;
            // border-color: #282844;
          }

          h3 {
            text-align: center;
            font-size: 36px;
            font-weight: 300;

            @include mobile {
              font-size: 28px;
            }
          }

          .each_container {
            width: 100%;
            display: grid;
            // grid-template-columns: repeat(auto-fit, minmax(min(150px, 100%), 1fr));
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 30px;
            padding: 0 40px;
            text-align: left;
            margin-bottom: 20px;

            @include mobile {
              grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
              gap: 12px;
              margin-bottom: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
