html {
  scroll-snap-type: y mandatory;
  // when the user scrolls will switch entirely to the new section
  scroll-behavior: smooth;
}

section {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  scroll-snap-align: center;
}

body {
  background-color: #0c0c1d;
  color: lightgray;
}

a {
  text-decoration: none;
  color: inherit;
}

@mixin mobile {
  // this function will be imported to the other scss file
  @media (max-width: 738px) {
    @content; // content placed upon the function in each scss
  }
}
@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1366px) {
    @content;
  }
}
