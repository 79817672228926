@import "../../app.scss";

.contact {
  height: 100%;
  max-width: 1366px;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 50px;
  width: 90%;

  @include mobile {
    width: 100%;
    padding: 10px;
    flex-direction: column;
    gap: 0;
  }

  .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include mobile {
      gap: 10px;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-top: 70px;
      flex: 0.6;
    }

    h1 {
      font-size: 100px;
      line-height: 88px;

      @include mobile {
        font-size: 36px;
        line-height: 60px;
      }
    }

    span {
      font-weight: 300;
    }
  }

  .formContainer {
    flex: 1;
    position: relative;

    @include mobile {
      padding: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .phoneSvg {
      stroke: orange;
      margin: auto;
      position: absolute;
      z-index: -1;

      svg {
        fill: none;
        stroke-width: 0.2;
        width: 500px;
        height: 500px;

        @include mobile {
          width: 100%;
          height: 100%;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include mobile {
        gap: 10px;
        width: 100%;
      }

      input,
      textarea {
        padding: 20px;
        background-color: transparent;
        border: 1px solid white;
        color: white;
        border: radius;

        @include mobile {
          padding: 10px;
        }
      }

      button {
        padding: 20px;
        border: none;
        background-color: orange;
        cursor: pointer;

        @include mobile {
          padding: 10px;
        }
      }
    }
  }
}
