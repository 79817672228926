@import "../../app.scss";

.navbar {
  height: 100px;

  .wrapper {
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 80%;

    @include mobile {
      justify-content: flex-end;
      padding: 20px;
    }

    span {
      font-weight: bold;
      margin: 0 20px;

      @include mobile {
        display: none;
      }
    }

    .social {
      display: flex;
      gap: 20px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
